// 2017.03.02 tongqing

@import "variables.scss";
// 审核列表
.audit-bd{ margin-top: 22px;
  .topic-lists{ margin-bottom: 28px;
    .item{ margin-right: 10px; float: left; }
    .topic-name{ margin-bottom: 5px; border: 1px solid $gray-light; line-height: 30px; padding: 0 16px; border-radius: 30px; font-size: 12px; color: $gray; cursor: pointer; }
    .topic-name.selected{ border-color: #6583C3; background-color: #6583C3; color: #fff; }
    .desc{ line-height: 30px; color: $gray-light; }
  }
}
.audit-tool-header{ font-size: 12px;
  .item{ float: left; margin-right: 30px;
    .count{ margin: 0 2px; color: #FF1111; }
  }
  .operation{ color: $theme-color;
    .option{ cursor: pointer; }
  } 
}

.audit-status{ line-height: 28px; padding: 0 6px; font-weight: bold; color: #fff; }
.preverify.audit-status{ background-color: #FF9F06; }
.pass.audit-status{ background-color: #3EB6B0; }
.notpass.audit-status{ background-color: #F55459; }
.delete.audit-status{ background-color: #8EBD5E; }
.reply.audit-status{ background-color: #4A90E2; }
.sensitive.audit-status{ background-color: #FF5C00; }
.long-text.audit-status{ background-color: #737e89; }

.audit-lists{ margin-top: 24px; padding-right: 20px; }
.audit-lists-item{ position: relative; margin-bottom: 20px; padding: 0 0 20px 28px;
  &:after{ content: ''; position: absolute; bottom: 0; left: 30px; right: 0; border-bottom: 1px solid #d9d9d9; }
  &.none{
    &:after{ display: none; }
  }
  .audit-status{ float: left; }
  .select-btn[type="checkbox"]{ -webkit-appearance: none; position: absolute; left: 2px; top: 8px; width: 12px; height: 12px; border: 1px solid #979797; border-radius: 2px; background-position: center; cursor: pointer;
    &:checked{ border-color: $theme-color; background: url($base64-icon-checkbox-arr) no-repeat $theme-color; background-position: center; box-shadow: 0 0 2px 0 rgba(69,188,184,1); }
  }
  .header{ line-height: 28px;
    .item{ float: left;}
    .avatar{ width: 28px; height: 28px; }
    .username{ margin: 0 4px 6px; font-weight: bold; }
    .level{ margin-top: 5px; padding: 0 5px; line-height: 17px; background-color: #F994AB; color: #fff; }
    .isblock{ margin: 0 12px 10px; color: $theme-color; cursor: pointer; }
    .time{ color: $gray; }
    .content-overview{ font-size: 12px; color: #8D8D8D; 
      .delimiter{ margin: 0 15px; }
      .middle{ margin: 0 15px; }
      .count{ margin-left: 2px; color: $gray-dark; }
    }
    .device-type{ color: $gray; }
  }
  .audit-content{ 
    .topic-text{ line-height: 24px;
      .audit-status{ margin-right: 8px; line-height: 24px;  }
      .content{ font-size: 14px; color: $gray; 
        .is-sensitive{ color: #FF1111; }
      }
    }
    .topic-album{  margin: 12px 0 10px;
      .item{  margin-bottom: 5px; float: left; margin-right: 6px; overflow: hidden; background-color: #e6e6e6;
        a{ position: relative; display: block;
          &:after{ content: ''; width: 100%; height: 100%; position: absolute; left: 0; top: 0; background: url(/admin/static/imgs/audit_icon_play.png) center no-repeat; background-size: 50px; }
        }
        .img{ display: block; width: 100px; height: 100px; background-size: cover; background-repeat: no-repeat; background-position: center; cursor: pointer; }
        .btn{ display: block; width: 100%; background-color: #333; text-align: center; color: #fff; cursor: pointer; }
      }
    }
  }
  .resource{ color: $gray-dark;
    .thread-name{ color: $theme-color; cursor: pointer; }
  }
  .thread-operation{ margin-top: 17px;
    .item{ line-height: 28px; margin-right: 8px; padding: 0 6px; float: left; background-color: #4990E2; color: #fff; cursor: pointer; }
    .item.check-item{ background-color: transparent; color: #333;
      .select-btn{ position: static; margin-right: 5px; }
      label{ cursor: pointer; }
    }
  }
  .pre-operation{ position: absolute; right: 0; top: 0;
    .audit-status{ margin-left: 8px; cursor: pointer; }
  }
}

// 主题详情
.circle-main-content{ padding: 0 20px; 
  .main-top-bar{ margin-left: 0; margin-right: 0; }
}
.thread-setting{
  .magx-btn{ margin: 18px 16px 0 0; }
}
.thread-content-bd{ position: relative; padding: 0 560px 0 0; }
.thread-detail-module{ margin-bottom: 18px; border-bottom: 1px solid #d9d9d9; padding-bottom: 30px;
  &:last-child{ border-bottom: 0; }
  .module-title{ margin-bottom: 15px; color: $gray-dark; font-weight: bold; font-size: 12px; }
  .user-info{ margin: 21px 0 18px;
    .avatar{ display: block; width: 28px; height: 28px; float: left; margin-right: 8px; }
  }
  .wrap{
    .username{ line-height: 12px; color: $gray-dark; font-weight: bold; }
    .post-info{ line-height: 16px; color: $gray; }
    .post-info-item{ margin-right: 16px; 
      .thread-name{ color: $theme-color; }
    }
  }
  .magx-pager{ position: static; margin-top: 27px; padding: 0; }
}
.thread-additional-info{ margin-bottom: 20px;
  .item{ margin-right: 10px; color: #8D8D8D; 
    .value{ margin-left: 4px; color: $gray-dark; }
  }
}
.thread-content{
  .audit-status{ margin-right: 5px; float: left; line-height: 24px; height: 24px; }
  .content{ line-height: 24px; color: $gray-dark; font-size: 14px; 
    .special-color{ color: #FF1111; font-weight: bold; }
  }
}
.thread-album{ margin: 16px 0 0;
  .item{ margin-right: 4px; float: left;
    .img-wrap{ display: block; width: 48px; height: 48px; overflow: hidden;
      .img{ width: 100%; }
    }
  }
}

.thread-operation{ margin-top: 22px;
  .magx-btn{ float: left; margin-right: 12px; color: #fff; }
  .delimiter{ float: left; width: 1px; height: 26px; margin: 3px 12px 0 0; background-color: #d9d9d9; }
  .save{ background-color: $theme-color; }
  .pass{ background-color: #3EB6B0; }
  .notpass{ background-color: #F55459; }
}

.audit-modify{ width: 518px; position: absolute; right: 0; top: 13px;
  .title{ margin-bottom: 6px; line-height: 17px; color: $gray-light; font-size: 12px; }
  .modify-area{ display: block; width: 518px; height: 143px; padding: 12px 14px; font-size: 14px; color: $gray; }
}

.praise-user-lists{ margin-bottom: 23px;
  .item{ float: left; width: 28px; height: 28px; margin-right: 4px;
    .img{ width: 100%; }
  }
}

.praise-random{
  .magx-btn{ float: left; margin-right: 12px; 
    &.btn-praise-nums .border-top-arr{ display: inline-block; position: relative; top: 5px; margin-left: 3px; }
    &.btn-praise-nums .border-top-arr.turn-up{ top:-2px; }
  }
  .note{ width: 574px; overflow: hidden; font-size: 12px; color: $gray; }
}

.praise-area{
  .operation{ margin: 24px 0 21px;
    .item{ float: left; margin-right: 20px; line-height: 26px; color: $theme-color; cursor: pointer; }
    .praise-btn{ width: 72px; height: 26px; background-color: $theme-color; color: #fff; }
  }
  .user-lists{
    .item{ width: 20%; float: left; line-height: 20px; margin-right: 70px;
      input[type="checkbox"]{ width: 12px; height: 12px; border: 1px solid #979797; border-radius: 2px; background-position: center; -webkit-appearance: none; cursor: pointer;
        &:checked{ border-color: $theme-color; background: url($base64-icon-checkbox-arr) no-repeat $theme-color; background-position: center; box-shadow: 0 0 2px 0 rgba(69,188,184,1); }
      }
      .username{ margin: 0 0 0 4px; background-size: 12px 12px; background-position: right 3px; background-repeat: no-repeat; padding-right: 15px; font-size: 14px; color: $gray-dark; cursor: pointer; }
    }
    .item.female{ 
      .username{ background-image: url($base64-icon-female);}
    }
    .item.male{ 
      .username{background-image: url($base64-icon-male); }
    }
  }
}

.shortcart-comment{ line-height: 36px;
  .item{ float: left; }
  .avatar{ width: 28px; height: 28px; margin-top: 4px; } 
  .username{ position: relative; margin: 0 16px 0 6px; padding-right: 20px; font-size: 12px; color: $gray-dark; font-weight: normal; cursor: pointer;
    &:after{ content: ''; position: absolute; right: 0; top: 15px; width: 0; height: 0; border: 5px solid $gray-light; border-bottom: 0; border-left-color: transparent; border-right-color: transparent; }
  }
  .reply-comtent{ width: 479px; height: 36px; margin-right: 16px; border: 1px solid $gray-lighter; padding: 0 0 0 10px; border-radius: 4px; }
  .magx-btn{ margin-right: 8px; }
  .comment.magx-btn{ background-color: $theme-color; color: #fff; }
}

.audit-comment-lists{ width: 100%; margin-top: 30px;
  tr{ border-bottom: 1px solid #d9d9d9; }
  .user-info-wrap{ width: 15%; padding: 13px 0;
    .avatar{ width: 28px; height: 28px; float: left; margin-right: 8px; }
    .user-info{ margin: 0; overflow: hidden;
      .username{ color: $gray-dark; font-weight: bold; }
      .time{ display: block; color: $gray-lighter; }
    }
  }
  .content{ width: 65%;
    .audit-status{ margin-left: 8px; height: 24px; line-height: 24px; float: left; }
    .comment-value{ line-height: 24px; margin-left: 10px; color: $gray-dark; font-weight: bold; }
    .issensitive{ color: $gray-dark; font-weight: bold; 
      .special-color{ color: #FF1111; }
    }
  }
  .operate{ width: 20%;
    .audit-status{ float: right; margin-left: 8px; }
  }
}

// 2018.06.04 tongqing 审核UI重构
.audit-list-item_header{ position: relative; margin-bottom: 5px; padding-left: 46px;
  .avatar-wrapper{ position: absolute; left: 0; top: 0;
    .avatar{ display: block; width: 36px; height: 36px; border-radius: 100%; }
    .cert-pic{ width: 16px; position: absolute; right: 0; bottom: 0; }
  }
  .userinfo{ font-size: 12px;
    .username{ color: #545454; }
    .level{ height: 16px; vertical-align: sub; }
    .reply-info{ color: #8C8C8C; 
      .user{ color: #84A3C2; }
    }
    .op{ margin-left: 5px; color: #5ACD7C; }
  }
  .addtion{ margin-bottom: 5px; font-size: 12px; color: #8C8C8C; }
}
.audit-list-item_content{ margin-bottom: 16px; line-height: 24px; font-size: 15px; color: #1B1B1B; }
.audit-list-item_album{max-width: 312px;
  .item{ position: relative; display: inline-block; width: 108px; height: 108px; margin-right: 6px; margin-bottom: 6px; background-size: cover; background-position: center; background-repeat: no-repeat; 
    &.single{ width: 196px; height: 350px; background-size: contain; background-position: left center; }
    .btn{ display: none; width: 100%; background-color: #333; position: absolute; bottom: 0; text-align: center; color: #fff; cursor: pointer; z-index: 4; }
    &:hover .btn{ display: inline; }
  }
}
.audit-list-item_video{ display: block; margin-bottom: 16px;
  .img{ width: 196px; height: 196px; background-size: cover; background-position: center; }
  .video-play-tag{ width: 50px; position: absolute; top:50%; left: 50%; transform: translate(-50%,-50%);}
}