@charset 'utf-8';
@import 'variables.scss';

.application-content-bd{ padding: 22px 20px 28px 20px;
  .app-title{ border-bottom: 1px solid rgba(0, 0, 0, 0.08); padding-bottom: 22px; line-height: 25px; font-size: 18px; font-weight: bold; color: $gray-dark;}
  .switch-menu{box-sizing: border-box; width: 200px; height: 24px; line-height: 22px; margin: 29px 0 28px -5px; border: 1px solid $gray; border-radius: 4px; overflow: hidden; text-align: center;
    .btn{ width: 50%; float: left; font-size: 12px; color: $gray; cursor: pointer; transition: all 0.3s;}
    .btn.current{ background-color: $gray; color: #fff;}
  }
}
.app-lists{
  .category{ margin-bottom: 24px; line-height: 17px; font-size: 12px; font-weight: normal; color: $gray-dark;}
  .application-item{ margin-bottom: 15px; }
}
.application-item{
  .app-thumb{ width: 48px; height: 48px; border-radius: 6px; margin-right: 22px; float: left; }
  .application-content{ position: relative; overflow: hidden; border-bottom: 1px solid rgba(0, 0, 0, 0.08); padding-bottom: 20px;
    .title{ line-height: 20px; font-weight: bold; font-size: 14px; color: $gray-dark; }
    .info{ margin: 5px 0 11px; font-size: 12px;
      .desc{ margin-right: 5px; color: $gray-light; }
      .update{ margin-left: 10px; color: #F52323; }
    }
    .app-desc{ max-width: 587px; margin-bottom: 13px; line-height: 17px; font-size: 12px; color: $gray-dark; }
    .magx-btn{ line-height: 32px; position: absolute; right: 0; top: 0; }
    .magx-btn.valid{ background-color: $theme-color; color: #fff; }
    .magx-btn.invalid{ pointer-events:none; background-color: $gray-lightest; color: $gray-dark; }
    .application-detail{ font-size: 12px; color: $theme-color; cursor: pointer; }
    .operation-lists{ line-height: 17px; position: absolute; right: 0; top: 0; color: $theme-color; font-size: 0;
      .item{ display: inline; margin-left: 20px; cursor: pointer; font-size: 12px;}
    }
  }
}

// 更多应用
.more-application{
  .category-desc{ margin-bottom: 24px; font-size: 12px; color: $gray-light;
    .highlight-link{ margin-left: 10px; color: $theme-color; }
  }
}

// 弹窗
.application-dialog{ padding: 0 18px 64px 10px; left: 50%; top: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);
  .btn-group{ position: absolute; right: 18px; bottom: 32px; font-size: 0;
    .magx-btn{ margin-left: 12px; text-align: center; font-size: 12px;}
    .valid{ background-color: $theme-color; color: #fff; }
    .invalid,
    .cancel{ background-color: $gray-lightest; color: $gray-dark; }
    .invalid{pointer-events: none;}
  }
}
.application-dialog-header{ margin-bottom: 13px; border-bottom: 1px solid rgba(0, 0, 0, 0.08); padding: 13px 24px 11px 0; position: relative; 
  .title{ line-height: 20px; font-size: 14px; color: $gray-dark; }
  .btn-close{ top: 10px; right: 0; cursor: pointer;}
}
.application-dialog-content{ position: relative; padding-left: 7px;
  .application-content{ position: relative; border-bottom: 0; padding-bottom: 0;
    .app-dialog{ margin-bottom: 5px !important;}  
    .version-lists{ max-height: 160px; overflow-y: scroll; margin: 10px 0; line-height: 23px; font-size: 12px; color: $gray-dark;}
    .app-pay-note,
    .app-pay-desc{ line-height: 23px; font-size: 12px; color: $gray-dark; }
    .app-pay-note{
      .custom-service{ margin-left: 5px; font-size: 12px; color: $theme-color; }
    }
  }
}
.app-pay{ padding-left: 60px; }

// 安装步骤
.app-install-status{ padding: 26px 0 30px;
  .unarchiver{
    .unarchiver-title{ margin-bottom: 10px; line-height: 23px; font-size: 12px; color: $gray-dark; }
    .progress{ display: block; width: 100%; height: 8px; background: $gray-lightest;
      .progress-value{ height: 100%; background-color: $theme-color; } 
    }
  }
  .install-result{ padding-left: 21px; line-height: 23px; background-position: left center; background-size: 16px 16px; background-repeat: no-repeat; font-size: 12px; color: $gray-dark;}
  .install-result.success{ background-image: url($base64-icon-nike);}
  .install-result.failed{ background-image: url($base64-icon-failed);
    .error-log{ color: $theme-color;}
  }
}

//扫码支付购买应用
.app-pay-info{ float: left; margin-right: 34px; line-height: 23px; text-align: center;
  .app-pay-qrcode{ width: 96px; height: 98px; }
  .info{ font-size: 12px; color: $gray-dark; }
  .price{ font-size: 20px; font-weight: bold;  color: $gray-dark; }
}
.pay-success{ padding-left: 23px;
  .pay-success-icon{ width: 56px; height: 56px; border-radius: 50%; float: left; margin-right: 50px; }
  .success-info{ line-height: 23px; color: $gray-dark;
    .title{ font-size: 12px;
      .price{ font-size: 20px; font-weight: bold; }
    }
    .success-info{ 
      .detail{ margin-left: 8px; line-height: 17px; color: $gray-light; }
    }
  }
}

// 邀请好友后台，用户留存计算
.invite-dialog-user{ font-size: 14px; color: #333; font-weight: bolder;
  .item{ margin-right: 24px; }
}
.invite-dialog-toast{ border-radius: 6px; padding: 12px 45px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: rgba(0, 0, 0, 0.7); color: #fff; }