.config-functions-item{ margin-bottom: 20px; border: 1px solid #e5e5e5; 
  .header{ position: relative; padding: 10px;
    .magx-btn{ position: absolute; right: 10px; top: 50%; transform: translateY(-50%); }
  }
  .configuration{ border-top: 1px solid #e5e5e5; padding: 10px 0 10px 15px; background-color: #f1f1f1; 
    .magx-multi-select{ background-color: #fff; }
  }
  &.close{
    .magx-form.configuration{ display: none; }
  }
}