@charset "utf-8";

@import "variables.scss";

.magx-list.operation-spread{ margin-top: 20px;
    tbody td{ padding: 18px 0; vertical-align: top;}
    .img-box{ margin-right: 32px; max-height: 84px;}
    .spread-img{ max-width: 100%;}
    .posted-data{ padding-right: 50px;}
}
.operation-chat-list{ margin-top: 20px;
    li{ border: 1px solid #e8e8e8; padding: 16px; margin-bottom: 20px; overflow: hidden; }
    .user-panel{ float: left; width: 500px;
        .image{ float: left; margin-right: 14px; position: relative; }
        .read-num{ position: absolute; right:-8px; top:-8px; line-height: 16px; width:16px; height: 16px; text-align: center; color:#fff; background-color: #F54B48; border-radius: 8px; font-size: 10px; }
        .info{ overflow: hidden; }
        .intro-uname{ font-size: 14px; }
    }
    .update-time{ width: 120px; margin:0 auto; }
    .btns{ float: right; margin-top: 8px; }
    .magx-btn.normal{ margin-left: 12px; }
}
.operation-cash-hd{ padding: 30px 0 84px;
    .item{ height: 54px; line-height: 1.6; float: left; position: relative; padding:5px 0 0 74px; margin-right: 60px; }
    .item-today{ padding: 5px 90px 0 114px;
        .item-icon{ left:40px; }
        &:before,
        &:after{ content: '\20'; position: absolute; width: 1px; height: 30px; top: 10px; background-color: #DEDEDE; }
        &:before{ left:0; }
        &:after{ right:0; }
    }
    .item-icon{ position: absolute; left:0; top:0; }
    .money{ font-size: 18px; font-weight: bold; }
}
.operation-cash-chart{ margin-top: 40px;
    .bd{ width: 870px; height: 244px; }
    .annotation{ width: 870px; text-align: center; margin-top: 40px;
        .item{ position: relative; padding-left: 20px; margin:0 30px;
            &:before{ content: '\20'; position: absolute; width: 12px; height: 12px; left:0; top: 1px; border-radius: 6px; }
            &.add:before{ background-color: #2C80FE; }
            &.minus:before{ background-color: #FF5B58; }
        }
    }
}

.operation-card-entry{
    .magx-thmub .title{ font-size: 18px; 
        .new-tag{ display: inline-block; background-color: red; padding: 2px 9px; margin-left: 10px; line-height: 14px; border-radius: 18px; font-size: 12px; vertical-align: middle; color: #fff; }
    }
    .magx-thmub .desc{ font-size: 14px; color:#999; }
    .sum{ overflow: hidden; margin-top: 8px;
        .num{ padding:0 10px; position: relative;
            &:after{ content: '\20'; position: absolute; width: 1px; height: 100%; right: 0; top: 0; background-color: $single-line-color; }
        }
    }
    .sum-item{ margin-right: 10px; float: left;
        &:last-child .num:after{ display: none; }
    }
}


.magx-sum-panel .operation-card-vip{ float: left; margin-right: 20px; }
.operation-card-vip{ width: 323px; position: relative; border-radius: 10px; height:100px; overflow: hidden;
    &.quan{ box-shadow: 0 2px 4px 0px rgba(0,0,0,.16); }
    .bg-img{ width: 100%; display: block;}
    .icon{ position: absolute; left:24px; top:30px; width: 38px; height: 38px; }
    .shopper{ position: absolute; left:90px; top:24px; font-size: 14px; }
    .name{ position: absolute; left:90px; top:48px; font-size: 22px; }
}

.operation-cardlist-null{ padding: 72px 0;
    .content{ width: 520px; margin: 0 auto; padding:20px 0 0 180px; position: relative; }
    .img-plhd{ position: absolute; left:0; top:0; }
    .title{ font-size: 20px; margin-bottom: 8px; }
    .desc{ color:#bdbdbd; font-size: 14px; }
}

.operation-card-manage-form{ position: relative;
    .img-demo{ position: absolute; left:560px; top: 10px;}
    .userview{
      .userviewitem{font-size: 24px; color:#666;border-bottom: 2px solid #fff;
        &:hover{color:red},
      &.current{ color:$theme-color; border-bottom-color:$theme-color; }
    }
  }
}

/* 社区助手 */
.has-aside-kits{ position: relative; overflow: hidden;}
.entry-list{
    &>.item{ margin-bottom: 40px;}
    .btn-add-assit{ position: absolute; right: 0; top:0;}
}

/* 社区助手新增消息 */
.ly-img{ @include use-background-image; background-color: #F1F1F1; overflow: hidden; position:relative; }
.ly-row{ position: relative; overflow: hidden; color:#999; }
.ly-justify-field{ text-align: justify; overflow: hidden; line-height: 20px; width:52px; height: 20px; float:left;
    &:after{ display: inline-block; content:''; width:100%; height: 100%; text-align: justify; overflow: hidden; }
}
.ly-justify-val{ position: relative; overflow: hidden; line-height: 20px; padding: 0 0 0 14px;
    &:after{ content: '：'; position: absolute; left: 3px; top:-1px; }
}
.assit-msg-edit{ margin: 20px 0; position: relative; width: 388px;
  .font-mid{ margin-bottom: 16px; }
  .multi-fields{ margin-bottom: 10px; }
  .colorful-desc{ font-size:14px; line-height: 20px; margin-bottom: 10px;}
  .colorful-kits-wrap{ position: relative; overflow: hidden; margin-bottom: 14px;
    .color-picker{ position: absolute; right:13px; top:50%; padding:4px; border-radius: 24px; border:1px solid #CCCCCC; transform: translate(0, -50%); -webkit-transform: translate(0, -50%); }
  }
  .magx-textfield{ height: 40px; margin-bottom: 14px; width: 100%;
    &.colorful{ padding-right: 48px; margin:0; }
  }
  .magx-textarea{ width: 100%;
    &.colorful{  padding-right: 48px; }
  }
  .edit-desc{ margin: 14px 0 16px 0; }
  .edit-desc-row{ overflow: hidden; position: relative;
    .magx-textfield{ float:left;}
    .operate-listcell{ bottom:14px; }
    &:hover .operate-listcell-action{ display: block; }
  }
  .btn-add-desc{ line-height: 40px; text-align: center; color:#666; border: 1px dashed #C2C2C2; border-radius: 4px; cursor: pointer; }
  .check-detail{ padding-top: 14px; color:#999; position: relative; border-top:1px solid $single-line-color;
    &:after{ @include arrow-by-border; width:7px; height: 7px; transform: rotate(45deg); position: absolute; right:0; top:18px;}
  }
  .show-demo-box .check-detail{ color:#222; padding-bottom: 6px; }
  .tpl-has-left-pic{ overflow: hidden;
    .cover-wrapper{ margin-top: 14px; }
    .edit-desc{ float: left; width: 248px; margin-left: 8px; }
    .magx-textarea{ margin-bottom: 14px; }
  }
  .panel{ padding: 14px; border:1px solid #9D9D9D; margin-bottom: -1px; position: relative; }
  .add-link{ position: absolute; right:-64px; top:0; line-height: 24px;}
  .cover-wrapper{ position: relative;
    &.type1{ height: 180px;}
    &.type2{ width: 56px; height: 56px;}
    &.type3{ width: 102px; height: 102px;}
  }
  .cover-title{ position: absolute; left:0; right:0; bottom:0; padding:10px; background-color: rgba(0,0,0,.75);}
  .cover-title-ipt{ width:100%; height: 24px; color:#fff; font-size: 14px; background-color: rgba(0,0,0,0);
    &::-webkit-input-placeholder,
    &:focus::-webkit-input-placeholder{ color:#fff; }
    &:focus{ box-shadow: none;}
  }
  .add-short-msg{ height: 86px; border:1px dashed #C2C2C2; background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAINJREFUSA3tl0EKwCAMBE3p6/rH5Bft91Jz8b67Bw9GEER2UEcwOAbZIuKtTuLjZsHMfFi2uEuBFbYXVuxBbKuGdCnhVq3Yg9jzVFtVGPXBhxzPsJl9W1TPgya62ZV396y+JsDBlhPXHnth8Kb4eKvm3YFkqwaF8fHzVNN/pyptSpX5ARoCHY3tUyrwAAAAAElFTkSuQmCC") no-repeat center center; cursor: pointer; }
  .short-msg .magx-textarea{ width: 287px; height: 56px; }
  .short-msg:hover .operate-listcell-action{ display: block; }
  .btn-group{ margin-top: 24px;
    .magx-btn{ float: left; margin-right: 12px; }
  }
  .show-demo{ position: absolute; left:615px; top:0; width:300px;
    .submsgs{ margin-top: 10px;
      li{ padding: 10px 0 0 0; border-top:1px solid $single-line-color; overflow: hidden; margin-bottom: 10px; position: relative; }
      .thumb{ float: right; margin-left: 8px; }
    }
    .submsg-title{ width: 226px; position: absolute; left:0; top:50%; transform:translate(0, -50%); font-size: 14px; line-height: 20px; @include clamp-txt; -webkit-line-clamp: 2;  }
  }
  .show-demo-box{ padding: 8px; border:1px solid #E0E0E0; border-radius:4px; margin-top: 14px; box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2);
    .cover-title{ color:#fff; padding: 3px 8px; line-height: 20px; font-size: 14px; @include clamp-txt; -webkit-line-clamp: 2; }
    .pic-text-title{ color:#222; font-size: 16px; line-height: 24px; margin-bottom: 6px; }
    .time{ color:#999; }
    .tpl-has-left-pic{ margin-bottom: 10px;
      .thumb{ float: left; margin-right: 10px; }
      .texts{ position: relative; overflow: hidden; }
      .texts-title{ font-size: 14px; color:#222; @include clamp-txt; -webkit-line-clamp: 2; line-height: 20px; margin-bottom: 8px; }
      .texts-desc{ color:#666; @include hide-txt; }
    }
  }
}

/**
 * 2018.06.20 用户详情，认证资料状态选择
 */

 .userview-user-cert{ border-bottom: 1px solid #e9e9e9;
     .item{ margin-right: 20px; padding: 5px 0; font-size: 12px; }
     .current{ border-bottom: 2px solid #42bd56; color: #42bd56; }
 }