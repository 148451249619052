@charset "utf-8";

@import "variables.scss";

// 圈子
.dockforum-list{
    .row{ padding: 22px 0; border-bottom: 1px solid $single-line-color; 
        li{ min-height: 20px; margin-bottom: 10px; padding-left: 25px; }
    }
    .item{ float: left; width: 140px; margin: 14px 0 0 4px; @include hide-txt;}
    .btn-next-step{ margin-top: 30px;}
    .magx-checkbox{ display: inline-block; margin-right: 10px; min-width: 150px; max-width: 150px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
        &.category{ float: left; }
        &:hover{ max-width: none; }
    }
    .sub-wrapper{ overflow: hidden; }
}
.config-selected-forum{
    .magx-table tbody td{ padding: 20px 0;}
    .magx-thmub .title{ padding-left: 14px; line-height: 40px;}
    .magx-textfield.show-name{ width: 162px;}
    .magx-textfield.phrase{ width: 302px;}
    .btn-group{ margin: 34px 0 0 0; overflow: hidden;
        .magx-btn{ margin: 0 16px 0 0;}
    }
}
.magx-list{
    .btn-hspace{ margin-left: 42px;}
    &.community{ margin: 10px 0 0 0;
        .magx-table td{ padding: 10px 0; }
        .magx-thmub{ cursor: pointer;}
        .magx-thmub.no-detail{ cursor:default; }
        .magx-thmub .image{ margin-right: 10px;}
        .sum-nums{ font-size: 14px;}
        .sum-nums .item{ margin-right: 12px;}
        .select-btn{ webkit-appearance: none; width: 12px; height: 12px; border: 1px solid #979797; border-radius: 2px; background-position: center; cursor: pointer; }
        .select-btn[type="checkbox"]:checked{ border-color: #42BD56; background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAHCAYAAADam2dgAAAAAXNSR0IArs4c6QAAAFpJREFUGBljYMAB/v//7wPEQjikGRiAkpFA/AeIzwMxB1ghkGEM0wFkwxQAmf9rYQoKQTwgKAViTAUgVUCJRCD+C8QgAKMhJsCMx6IQUwFMMdAUkIk1MD4yDQBGxWx4PFX3ZAAAAABJRU5ErkJggg==") no-repeat #42BD56; background-position: center; box-shadow: 0 0 2px 0 #45bcb8; -webkit-appearance: none; }
    }
    &.topiclist{ margin: 18px 0 0 0;
        tbody td{ padding: 18px 0; vertical-align: initial; }
        .magx-thmub{ cursor: pointer;}
        .magx-thmub .image{ margin-right: 8px;}
        .magx-thmub .title{ font-size: 12px; line-height: 14px;}
        .posted-data{ padding-right: 100px;}
    }
    &.fanslist{
        tbody td{ padding: 12px 0;}
        .btn-hspace{ margin-left: 24px;}
        .magx-thmub .image{ margin-right: 10px;}
        .magx-thmub .title{ font-size: 12px; line-height: 20px;}
    }
    &.cmtlist{ margin: 18px 0 0 0;
        tbody td{ padding: 14px 14px 14px 0; vertical-align: initial;}
        .posted-data{ max-width: 560px; /*font-weight: bold;*/}
    }
}
.community-info-panel{ margin:12px 16px 0 16px; padding-bottom: 12px; border-bottom: 1px solid $single-line-color;
    .magx-thmub .image{ width:48px; height: 48px; margin-right: 10px;}
    .note-type{ margin:12px 0 0 0; background-color: #fff; line-height: 24px; padding: 0 0 0 10px; border-radius: 4px;}
}
.community-single-barmain{ position: absolute; left: 0; top: 118px; bottom: 0; width: 100%; padding: 0 0 16px 0;
    .suminfo{ margin: 16px; padding-top: 16px; border-top: 1px solid $single-line-color;
        .items{ padding: 10px 0 0 12px; line-height: 24px;}
        .info{ margin-right: 12px;}
    }
}
.topiclist-topinfo{ margin-bottom: 18px;}
.topic-cv{
    .magx-thmub .image{ margin-right: 8px;}
    .magx-thmub.canselect{ cursor: pointer; }
    .magx-thmub.userpost .title{ font-size: 12px; line-height: 14px;}
    .detail{
        .post-area{ float: left; width: 560px;}
        .posted-data{ margin: 12px 0; font-size: 14px;}
        .magx-btn{ margin-left: 16px;}
        .magx-textarea{ width: 100%; height: 82px; margin: 16px 0; font-size: 14px;}
    }
    .action-num{ margin-left: 8px;}
    .praise{ margin: 16px 0 18px 0; padding: 14px 0 24px 0; border-top: 1px solid $single-line-color; border-bottom: 1px solid $single-line-color;
        .images-hgroup{ width: 560px; margin-top: 8px; }
    }
    .btn-with-desc{ margin: 24px 0 0 0; }
    .post-btn-group{ margin: 30px 0 0 0;padding: 20px 0 0 0; border-top: 1px solid $single-line-color;
        .magx-btn{ margin-right: 10px;}
    }
}
.comment-action{ margin: 18px 0 0 0;
    .magx-thmub{ max-width: 160px; margin-top:2px; }
    .magx-textfield,
    .magx-btn,
    .magx-thmub{ float: left; }
    .magx-textfield{ width: 480px; margin: 0 16px; }
    .magx-btn{ margin-right: 8px; }
    .magx-thmub .title{ font-size: 12px; line-height: 28px;}
}
.community-quick-cmt{ margin: 24px 0 0 0;
    .item{ overflow: hidden; margin-top: 12px; }
    .faceimg,
    .magx-textfield{ float: left; }
    .magx-textfield{ width: 384px; margin-left: 12px;
        &:focus{ color:#F03084; border-color:$form-control-border-color; box-shadow: none; }
    }
    .btn-with-desc{ margin: 18px 0 0 0; }
}
.community-gconfig{ 
    .appindex-edited-entry{ width:700px; margin:0; }
}

.themes-point-zan-content{ max-width: 100px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }